@import url("https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap");
body {
	margin: 0;
	font-family: "Playfair Display SC", serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.link:focus {
	outline: none;
}

.text-focus-in {
	-webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.text-focus-in-2 {
	-webkit-animation: text-focus-in 1.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: text-focus-in 1.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

.text-focus-in-3 {
	-webkit-animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
	animation: text-focus-in 1.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}
@keyframes text-focus-in {
	0% {
		-webkit-filter: blur(12px);
		filter: blur(12px);
		opacity: 0;
	}
	100% {
		-webkit-filter: blur(0px);
		filter: blur(0px);
		opacity: 1;
	}
}

.about-page {
	font-family: "Courier Prime", serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
